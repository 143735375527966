import {Component, OnInit, ViewChild, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ConfigurationService, UserService, AppSidebarService} from '@universis/common';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styles: [`
  :host {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
  }
  `]
})
export class IndexComponent implements OnInit {

  constructor(private _configurationService: ConfigurationService) { }

  public today = new Date();
  public languages: any;
  public currentLang: string;
  public applicationImage: string;
  public applicationTitle: string;
  public applicationFooter: string;

  ngOnInit(): void {
    // get current language
    this.currentLang = this._configurationService.currentLocale;
    // get languages
    this.languages = this._configurationService.settings.i18n.locales;
    // get path of brand logo
    const appSettings: { image?: string; title?: string , footerImage?: string} = this._configurationService.settings.app;
    this.applicationImage = appSettings && appSettings.image;
    // get application title
    this.applicationTitle = (appSettings && appSettings.title && appSettings.title[this.currentLang]) || 'Universis';
    // get applicationFooter
    this.applicationFooter = appSettings && appSettings.footerImage && appSettings.footerImage[this.currentLang];
  }

  changeLanguage(lang: any): void {
    this._configurationService.currentLocale = lang;
    // reload current route
    window.location.reload();
  }
}
